// Cache element selectors
const menuButton = document.querySelector('#menu-button');
const menuIcon = document.querySelector('#menu-button input');
const header = document.querySelector('header');
const reelWrapper = document.querySelector('.reel-wrapper');
const imageFade = document.querySelector('#image-fade');
const logo = document.querySelector('#logo');
const bar = document.querySelector('#bar');
const divs = document.querySelectorAll('#pagination div');
let _timeout = null;
let index = 0;

/**
 * Event listener for the menu icon. When clicked it toggles various
 * classes required to make it animate out and push over the reel wrapper.
 */
menuIcon.addEventListener('click', () => {
  header.classList.toggle('menu-open');
  reelWrapper.classList.toggle('reel-hidden');
  imageFade.classList.toggle('fade-active');
  menuButton.classList.toggle('shift');
});

divs.forEach((element) => {
  element.addEventListener('click', function(event) {
    const _index = parseInt(event.target.dataset.slide, 10) - 1;
    clearTimeout(_timeout);
    focusImage(_index);
    changeCaption(_index);
    index = _index;
    incrementCounter();
  });
});

const imageCaptions = [
   {
    header: 'Wine Wall',
    sub: 'Hockley, Essex',
  },
  {
    header: 'Dual Wine Display',
    sub: 'Chalfont St Giles, Bucks',
  },
  {
    header: 'Suzanne Neville - FLORES 2020',
    sub: 'Marylebone, London',
  },
  {
    header: 'Wine Room',
    sub: 'Rayleigh, Essex',
  },
  {
    header: 'Wine Cabinet',
    sub: 'Rayleigh, Essex',
  },
  {
    header: 'Pet Portrait',
    sub: 'Surrey, UK',
  },
  {
    header: 'Street Style',
    sub: 'Shoreditch, London',
  },
  {
    header: 'Kitchen Rennovation',
    sub: 'Southend-On_Sea, Essex',
  },
  {
    header: 'Tanglewood Wine Room',
    sub: 'Chislehurst, London',
  },
  {
    header: 'Product Photography',
    sub: 'Studio, Essex',
  },
];

/**
 * Changes the image caption text based on the current index and
 * what it matches in the captions array.
 * @param {number} index
 */
function changeCaption(index) {
  if (index >= imageCaptions.length) return;
  const text = imageCaptions[index];
  document.querySelector('#caption-wrap').dataset.index = index;
  document.querySelector('#caption-header').innerHTML = text.header;
  document.querySelector('#caption-sub').innerHTML = text.sub;
}

/**
 * Brings an image into focus by setting it's opacity to 1 and the
 * rest of the images to have a zero opacity.
 * @param {number} index
 */
function focusImage(_index) {
  const images = document.querySelectorAll('.portfolio-image');
  images[_index].style.opacity = 1;

  images.forEach((image, index) => {
    if (index === _index) return;
    image.style.opacity = 0;
  });
}

/**
 * Used to increment the index counter that is used to find the position in the array
 * of divs that display the numbers. This function will run until it reaches the arrays length
 * and then will loop around continuously by resetting the index to zero.
 */
function incrementCounter() {
  if (index === 0) {
    setActiveNumber(index);
    focusImage(index);
    changeCaption(index);
    index++;
    progress(300, 300, document.querySelector('#progressBar'));
  } else if (index < divs.length) {
    setActiveNumber(index);
    focusImage(index);
    changeCaption(index);
    index++;
    progress(300, 300, document.querySelector('#progressBar'));
  } else {
    index = 0;
    incrementCounter();
  }
}

function setActiveNumber(number) {
  divs[number].classList.add('active');
  divs.forEach((element, index) => {
    if (index === number) return;
    divs[index].classList.remove('active');
  });
}

/** Recursive function to reduce the progress bar width down to zero based on the time
left. */
function progress(timeleft, timetotal, $element) {
  const progressBarWidth = (timeleft * $element.offsetWidth) / timetotal;
  bar.style.width = progressBarWidth + 'px';

  if (progressBarWidth === 0) {
    bar.style.width = '0';
  }

  // If the time left is greater than zero continue, otherwise increment counter.
  if (timeleft > 0) {
    _timeout = setTimeout(function() {
      progress(timeleft - 1, timetotal, $element);
    }, 25);
  } else {
    incrementCounter();
  }
}

incrementCounter();
